import React, {Fragment} from "react";
import {useSelector} from "react-redux";
import {deleteReservationTemp} from "../../server/services/reservation-service";
import Translator from "../translator";
import {removeFromShoppingCart, CartItemType} from "../actions/shop";
import OptionsPopup from "../restaurant/options-popup/options-popup.js";
import "./panier-item.css";
import {useDispatch} from "react-redux";
import withNavigation from "../higher-order/navigation";
import {format as formatDate, parseJSON} from "date-fns";

function PanierItem(props) {
    const TEXTS = Translator(props.match.params.language, "TXT_PANIER_ITEM");
    const shop = useSelector((state) => state.shop);
    const dispatch = useDispatch();

    const modifierBillet = (item, index) => {
        item['index'] = index;
        props.gotoBilletUnite(item);
    }

    const removeItem = () => {
        const itemEffacer = props.item;
        if (!itemEffacer.idsReservationTemp) {
            dispatch(removeFromShoppingCart(itemEffacer, true, props.match.params.language));
            return;
        }
        const cartFiltered = shop.cart.filter(itemPanier => itemPanier.idsReservationTemp && itemPanier.idsReservationTemp != itemEffacer.idsReservationTemp);
        cartFiltered.forEach((itemPanier) => {
            if (!itemPanier.selectedUnite.idReservationTempParent && !itemEffacer.selectedUnite.idReservationTempParent) return;

            if (arraysIdentical(itemEffacer.idsReservationTemp, itemPanier.selectedUnite.idReservationTempParent) ||
                arraysIdentical(itemEffacer.selectedUnite.idReservationTempParent, itemPanier.idsReservationTemp) ||
                arraysIdentical(itemEffacer.selectedUnite.idReservationTempParent, itemPanier.selectedUnite.idReservationTempParent)) {
                deleteReservationTemp(itemPanier.idsReservationTemp).then(dispatch(removeFromShoppingCart(itemPanier, true, props.match.params.language)));
            }
        });

        deleteReservationTemp(itemEffacer.idsReservationTemp).then(dispatch(removeFromShoppingCart(itemEffacer, true, props.match.params.language)));
    };

    function arraysIdentical(a, b) {
        if (!Array.isArray(a) || !Array.isArray(b)) return a === b;
        var i = a.length;
        if (i != b.length) return false;
        while (i--) {
            if (Array.isArray(a[i]) || Array.isArray(b[i])) return arraysIdentical(a[i], b[i]);
            if (a[i] !== b[i]) return false;
        }
        return true;
    };

    let modifyOptions;
    if (props.item.type === CartItemType.ReservationActivite) {
        modifyOptions = (
            <button className="panier-item-btn" onClick={() => props.gotoActiviteReservationModification(props.item)}>
                {TEXTS.MODIFIER}
            </button>);
    } else if (props.item.type === CartItemType.CommandeRestaurant) {
        modifyOptions = (
            <OptionsPopup
                isModifying={true}
                classButton="buttonAddToCart"
                index={props.index}
                item={props.item}
                //handleClose={props.handleClose} // undefined
                content={
                    <button className="panier-item-btn">{TEXTS.MODIFIER}</button>}
            />)
    }

    if (!shop) throw new Error("the shop must not be empty.");

    const getTrancheAgeById = (idTrancheAge) => {
        try {
            const tranche = props.item?.selectedUnite?.plan_base?.TrancheAge?.find(tranche => tranche.idAuxiliaire === idTrancheAge)?.trancheAge;
            return tranche;
        } catch (e) {
            console.log(e);
        }
    }
    const montantDetailParDateDebut = (props.item.montantDetail || []).reduce((groupedArray, detail) => {
        const dateDebut = formatDate(parseJSON(detail.dateDebut), "yyyy-MM-dd");
        const dateFin = formatDate(parseJSON(detail.dateFin), "yyyy-MM-dd");
        const keyValue = detail.typeDetail === 3 ? '' : `Du ${dateDebut} au ${dateFin}`;
        groupedArray[keyValue] = groupedArray[keyValue] ?? [];
        groupedArray[keyValue].push(detail);
        return groupedArray;
    }, {});

    return (
        <div className="maindiv" style={{display: "flex"}}>
            <div className="panier-item-card-section">
                <img className="panier-item-card-thumbnail"
                     src={props.item.image || require("../../../images/no-image.png")} alt="thumbnail"/>
            </div>
            <div className="panier-item-card-section">
                <span className="panier-item-card-info-nom">{props.item.name}</span>
                <span className="panier-item-card-info-description">{props.item.type}</span>
                <div>
                    {props.item.inclus && props.item.inclus.map((current, index) => {
                        return (
                            <span key={index}>
                <b>{current.name}</b>
                                {(current.textVal && `: ${current.textVal}`) || (current.value && `: ${current.value}`)}
                                {current.montant ?
                                    <b className="sommaire-options-font sommaire-options-price-font display-inline">&nbsp; = {current.montant.toFixed(2).toString()}&nbsp;$&nbsp;</b> : props.item.type === CartItemType.CommandeRestaurant &&
                                    <i> ({TEXTS.INCLUS})</i>}
              </span>);
                    })}

                </div>
            </div>
            <div className="panier-item-card-section" style={{
                width: "max-content",
                minWidth: "15%",
                padding: "0 0 0 5px",
                display: "flex",
                flexDirection: "column"
            }}>
                {montantDetailParDateDebut && Object.keys(montantDetailParDateDebut).length > 0 &&
                    <h3 style={{margin: '.8em'}}>{TEXTS.MONTANT_DETAILS}</h3>}
                <div style={{
                    fontSize: ".8rem",
                    flexGrow: 1,
                    overflow: "auto"
                }}>
                    {props.item.selectedUnite && Object.keys(montantDetailParDateDebut).map(dateDebut => {
                        return (
                            <Fragment key={dateDebut}>
                                <div style={{
                                    padding: "5px",
                                    backgroundColor: 'rgba( 255,255,255,0.8)'
                                }}>
                                    <span><b><u>{dateDebut}</u></b></span>
                                    {montantDetailParDateDebut[dateDebut]
                                        .sort((a, b) => {
                                            const AtrancheAge = getTrancheAgeById(a.idTrancheAge);
                                            const BtrancheAge = getTrancheAgeById(b.idTrancheAge);

                                            if (!AtrancheAge || !BtrancheAge) return 1;

                                            if (AtrancheAge.ageDebut > BtrancheAge.ageDebut)
                                                return -1;
                                            if (AtrancheAge.ageDebut < BtrancheAge.ageDebut)
                                                return 1;
                                            return 0;
                                        })
                                        .map(itemDetail => {
                                            const trancheAge = getTrancheAgeById(itemDetail.idTrancheAge);
                                            const description = trancheAge && itemDetail.typeDetail === 1 ?
                                                (trancheAge.isAdulte ?
                                                    `adulte(s) ${trancheAge.ageDebut} ${trancheAge.ageFin === 999 ? 'et plus' : `à ${trancheAge.ageFin}`}`
                                                    : `enfant(s) ${trancheAge.ageDebut} à ${trancheAge.ageFin}`)
                                                : itemDetail.description;
                                            const quantitePrixText = `${itemDetail.quantite} x ${itemDetail.prix.toFixed(2)}`;

                                            return (
                                                <Fragment
                                                    key={`${itemDetail.dateDebut}-${itemDetail.idUnite}-${itemDetail.idPlanDetailTrancheAge}`}>
                                                    <div>{`${itemDetail.nombrePersonnes !== 0 ? itemDetail.nombrePersonnes : ''} ${description}: ${quantitePrixText} = ${itemDetail.sousTotal.toFixed(2)} $`} </div>
                                                </Fragment>)
                                        })}
                                </div>
                            </Fragment>)
                    })}
                < /div>
                <span
                    className="panier-item-card-right-section">{props.item.montant && props.item.montant.toFixed(2)} $</span>
                <button onClick={removeItem} className="panier-item-btn">
                    {TEXTS.ENLEVER}
                </button>
                {props.item.options && modifyOptions}
            </div>
        </div>
    );
}

PanierItem.defaultProps = {
    nom: "",
    description: "",
    showCalendrier: true,
    showIcons: true,
    indisponibilites: [],
    heureArrivee: null,
    heureDepart: null,
    maxJoursEnAvanceReservation: null,
    calendrierInitialMonth: null,
    isActivite: false,
    typeHebergement: ""
};
export default withNavigation(PanierItem);