import React, { useState, useEffect } from "react";

// date
import { format } from "date-fns";

//css
import "../../css/unite-card.css";

// composant
import withNavigation from "./higher-order/navigation";
import LayoutContent from './unite-card-layout-content.js';
import RenderAnotherUnitChoice from './render-another-unit-choice.js';

// service
import Translator from "./translator";
import { getUniteImageById, getUnitePrixBase } from "../server/services/unite-service";

// type
import PropTypes from "prop-types";


const today = new Date();
today.setHours(0);
today.setMinutes(0);
today.setSeconds(0);
today.setMilliseconds(0);

function cap(value, min, max) {
    if (value < min) return min;
    if (value > max) return max;
    return value;
}

function UniteCard(props) {

    const [thumbnail, setThumbnail] = useState(null);
    // const [modifiedData, setModifiedData] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [classModifier, setClassModifier] = useState((props.showCalendrier ? " has-calendrier " : " no-calendrier ") + (props.showIcons ? " has-icons " : " no-icons ")); // le no c;est pour les activites
    const [prixBase, setPrixBase] = useState(0);
    const [texts, setTexts] = useState(Translator(props.match.params.language, "TXT_UNITE_CARD"));
    const [language, setLanguage] = useState(props.match.params.language);
    const [thumbnailIndex, setThumbnailIndex] = useState(0);
    const [images, setImages] = useState([1]);


    useEffect(() => {
        async function fetchData() {
            if (!thumbnail) {
                //if (!props.isActivite) {
                    const image = await getUniteImageById(props.idUniteList[0] || props.idUnite);
                    const prix = await getUnitePrixBase(props.idUniteList[0] || props.idUnite, (props.selectedArrivee ? format(props.selectedArrivee, "yyyy-MM-dd") : null), (props.selectedDepart ? format(props.selectedDepart, "yyyy-MM-dd") : null));
                    setThumbnail(image === null ? require("../../images/no-image.png") : "data:image/jpeg;base64," + image);
                    setPrixBase(prix);
                /*} else {
                    setThumbnail(null)
                    setPrixBase(null)
                }*/
                setIsDataLoaded(true)
            }
        }
        fetchData();
    }, [props.idUnite, props.idUniteList, props.selectedArrivee, props.selectedDepart, thumbnail]);

    useEffect(() => {
        if (props.language && props.language !== language) {
            setTexts(Translator(props.match.params.language, "TXT_UNITE_CARD"));
            setLanguage(props.language);
        }
    }, [props.language, language]);

    function gotoConsultationUnite() {
        if (props.isActivite) {
            props.gotoActiviteReservation(props.idTypeUnite, props.idUnite);
        }
        else {
            props.gotoChoixUnite(props.typeHebergement, props.idTypeUnite, props.idUnite);
        }
    }

    function rotateThumbnail(change) {
        let nextIndex = cap(thumbnailIndex + change, 0, 8);
        setThumbnailIndex(nextIndex);
    }

    const availabilityLevels = {
        disponible: texts.error0,
        "partiellement-disponible": texts.error1,
        indisponible: texts.error2,
        reserve: "Cette unité est déjà réservé"
    };


    return (
        <>
            {props.availabilityLevel && (
                <div className={`unite-card-availability-level ucal-${props.availabilityLevel}`}>
                    {availabilityLevels[props.availabilityLevel]} {props.availabilityLevel === "partiellement-disponible" && props.availabilityLevelDescription ? "(" + props.availabilityLevelDescription + ")" : ""}
                </div>
            )}
            <LayoutContent
                {...props}
                texts={texts}
                prixBase={prixBase}
                isActive={props.isActivite}
                classModifier={classModifier}
                classNameProps={props.className}
                typeHebergement={props.typeHebergement}
                onClickAjouterPanier={props.onClickAjouterPanier}
                showAddToShoppingCart={props.showAddToShoppingCart}
                onClickAdd={(e) => props.onClickAjouterPanier(props.idUnite)}
                onClickToggle={() => gotoConsultationUnite()}
            >
                <RenderAnotherUnitChoice
                    key={props.key}
                    nom={props.nom}
                    images={images}
                    eau={props.eau}
                    animaux={props.animaux}
                    fumeur={props.fumeur}
                    typeHebergement={props.typeHebergement}
                    nombrePersonnes={props.nombrePersonnes}
                    language={language}
                    thumbnail={thumbnail}
                    classModifier={classModifier}
                    description={props.description}
                    idUnite={props.idUnite}
                    selectedDepart={props.selectedDepart}
                    selectedArrivee={props.selectedArrivee}
                    calendrierInitialMonth={props.calendrierInitialMonth}
                    indisponibilites={props.indisponibilites}
                    onDayClick={props.onDayClick}
                    heureArrivee={props.heureArrivee}
                    heureDepart={props.heureDepart}
                    maxJoursEnAvanceReservation={parseInt(props.maxJoursEnAvanceReservation || 0)}
                    minDate={props.minDate}
                    maxSearchDate={props.maxSearchDate}
                    minSearchDate={props.minSearchDate}
                    isActivite={props.isActivite}
                    showIcons={props.showIcons}
                    showCalendrier={props.showCalendrier}
                />
            </LayoutContent>
        </>
    );
}

UniteCard.propTypes = {
    idTypeUnite: PropTypes.string.isRequired,
    idUnite: PropTypes.string.isRequired
};

UniteCard.defaultProps = {
    nom: "",
    description: "",
    showCalendrier: true,
    showIcons: true,
    indisponibilites: [],
    heureArrivee: null,
    heureDepart: null,
    maxJoursEnAvanceReservation: null,
    calendrierInitialMonth: null,
    isActivite: false,
    typeHebergement: "",
    onClickAjouterPanier: null
};



export default withNavigation(UniteCard);
